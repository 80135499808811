export const IsPresent = (selector) => {
    return null !== document.querySelector(selector)
}

export const LoadIfNeeded = async (selector, path, callback) => {
    const exists = null !== document.querySelector(selector)
    if (exists) {
        const m = await import(/* @vite-ignore */path)
        if (undefined !== callback)
        {
            callback(m)
        }

        if (undefined === callback)
        {
            // TODO check if defaul executes propber > export default ...
            m.default()
        }
    }
}